@import 'a3_variables.less';

/* main font */
body { font-family:@mainFont; font-size:@mainFontSize; }

.monospace { font-family: monospace !important; }

header .small:not(button) { font-size: 11px; font-weight: normal; line-height: 15px; text-transform: uppercase; }

h1.thin, h2.thin, h3.thin, h4.thin, h5.thin, h6.thin { margin-top:0; margin-bottom:0; }

a:focus { text-decoration: none; }

.disabled { opacity: .7;}

.opaque { opacity: .8; }

.line-through, .line-through * {
    text-decoration: line-through !important;
}

.x-small { font-size: 60%; }

.is-removed, .is-removed * {
    text-decoration: line-through !important;
    opacity: .8;
}

.inline-block { display: inline-block !important; }

.nowrap { white-space: nowrap; }

.super-size {font-size:175px;}
.text-xl { font-size: 25px; }

.no-margin { margin: 0 !important; }
.top-margin-negative-big { margin-top: -20px !important; }
.top-margin-negative { margin-top: -10px !important; }
.no-top-margin { margin-top: 0 !important; }
.top-margin-thin {margin-top:5px !important; }
.top-margin { margin-top: 10px !important; } 
.top-margin-big { margin-top: 20px !important; }
.top-margin-super {margin-top: 40px; }
.top-margin-auto { margin-top: auto !important; }

.no-bottom-margin { margin-bottom: 0 !important; }
.bottom-margin-thin { margin-bottom:5px !important; }
.bottom-margin { margin-bottom: 10px !important; }
.bottom-margin-big { margin-bottom: 20px; }
.bottom-margin-super {margin-bottom:40px; }
.bottom-margin-auto { margin-bottom: auto !important; }

.no-left-margin { margin-bottom: 0 !important; }
.left-margin-thin {margin-left:5px;}
.left-margin {margin-left: 10px;}
.left-margin-big {margin-left: 20px;}
.left-margin-super {margin-left: 40px;}
.left-margin-auto { margin-left: auto !important; }

.no-right-margin { margin-right: 0 !important; }
.right-margin-thin {margin-right: 5px !important; }
.right-margin {margin-right:10px;}
.right-margin-big {margin-right:20px;}
.right-margin-super {margin-right: 40px;}
.right-margin-auto { margin-right: auto !important; }

.top-border-none { border-top: none !important; }
.right-border-none { border-right: none !important; }
.bottom-border-none { border-bottom: none !important; }
.left-border-none { border-left: none !important; }

.top-border { border-top: 1px solid #CCC !important; }
.right-border { border-right: 1px solid #CCC !important; }
.bottom-border { border-bottom: 1px solid #CCC !important; }
.left-border { border-left: 1px solid #CCC !important; }

.h-center { margin-left: auto !important; margin-right: auto !important; }

.no-padding { padding: 0 }

.no-top-padding { padding-top: 0 !important; }
.top-padding {padding-top: 10px !important; }
.top-padding { padding-top: 15px !important; }
.top-padding-small { padding-top: 5px !important; }

.no-right-padding { padding-right: 0 !important;}
.right-padding-small {padding-right:5px;}
.right-padding { padding-right: 10px !important; }
.right-padding-big { padding-right: 15px; }

.no-left-padding { padding-left: 0 !important;}
.left-padding-small {padding-left:5px;}
.left-padding {padding-left: 10px !important; }
.left-padding-big { padding-left: 15px; }

.no-bottom-padding { padding-bottom: 0 !important; }
.bottom-padding { padding-bottom: 10px !important; }
.bottom-padding-big { padding-bottom: 15px !important; }

.text-large { font-size: 120%; }
.text-bold { font-weight: bold; }

.faint { opacity: .25; }

.width-auto { width: auto !important; }
.width-100p { width: 100% !important; }
.width-75 { width: 75px !important; }
.width-85 { width: 85px !important; }
.width-90 { width: 90px !important; }
.width-100 { width: 100px !important; }
.width-110 { width: 110px !important; }
.width-125 { width: 125px !important; }
.width-150 { width: 150px !important; }
.width-175 { width: 175px !important; }
.width-200 { width: 200px !important; }
.width-250 { width: 250px !important; }
.width-300 { width: 300px !important; }

.width-350 { width: 350px !important; }
.width-500 { width: 500px !important; }

.max-width-500 { max-width: 500px !important; }
.max-width-750 { max-width: 750px !important; }
.max-width-1000 { max-width: 1000px !important; }
.max-width-1200 { max-width: 1200px !important; }

.width-8-rem { width: 8rem !important;}

.height-auto { height: auto !important; min-height: auto !important;}
.height-25 { height: 25px !important; }
.height-40 { height: 40px !important; }

.height-100p { height: 100% !important; }

.min-height-15 { min-height: 15rem !important; }
.min-height-25 { min-height: 25rem !important; }
.min-height-100 { min-height: 100rem !important;}

.min-height-1p5 { min-height: 1.5rem !important; }
.min-width-10 { min-width: 10rem !important; }
.min-width-25 { min-width: 25rem !important; }

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }

/*@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/css/fonts/OpenSans.eot);
}*/

.help-popover { color: #555; }

/* responsive */
@media (max-width: 1366px) {
    body { font-size: 13px; }
}

/* bagde */
span.new-badge { width:39px; padding:2px 4px 1px 4px; margin-right:20px; display:inline-block; text-transform:uppercase; }
span.new-badge:after { content:'new'; }
span.fix-badge { width:29px; padding:2px 4px 1px 4px; margin-right:30px; display:inline-block; text-transform:uppercase; }
span.fix-badge:after { content:'fix'; }
span.badge.blinking {animation: blinker 1.5s linear infinite;}

@keyframes blinker{ 50% { opacity: .2; }}

// Alerts
.alert { padding: 10px 20px; }

h5 {margin-bottom:0;}


section h5.sub-heading {
    margin-top: 40px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: flex;
}

section > h5.sub-heading:first-child {
    margin-top: 5px;
}

section h5.sub-heading span {
    flex: 1;
    text-align: right;
    color: @charcoal;
}

    span.new-badge { 
        border-radius: 4px;
        text-align: center; 
    }
    
    span.fix-badge { 
        border-radius: 4px; 
        text-align: center; 
    } 

body .themed.a3 {
    .green { color:@green; }
    .orange {color:@orange; }
    .red {color:@warningOrange; }
    .light-gray {color: @gray; }
    .gray {color: @darkGray; }

    .highlightedText { font-weight: bolder; text-decoration: underline; }

    section h1 {}
    section h2 {}
    section h3 {}
    section h4 {}
    section h5 { color:@orange; font-weight:bolder; }
    section h6 { color:@orange; }

    .badge {
        background-color:@green; 
        font-weight:normal; 
        border-radius:@baseBorderRadius;

        font-weight: bold;
        border-radius: 2px;
        margin-left: 3px;
    }

    .badge.red { 
        background-color: @red; 
        color: @white; 
    }

    span.new-badge { background:@orange; color:#fff; }
    span.fix-badge { background:@charcoal; color:#fff; }

    .dropdown .dropdown-menu > li, 
    .dropdown .dropdown-menu > li a, 
    .dropdown .dropdown-menu > li button,
    .dropdown .dropdown-menu > li > i, 
    .dropdown .dropdown-menu > li > i,
    .dropdown.scrollable .dropdown-menu  .scroller li, 
    .dropdown.scrollable .dropdown-menu  .scroller li a, 
    .dropdown.scrollable .dropdown-menu  .scroller li span,
    .dropdown .dropdown-menu .list-group li, 
    .dropdown .dropdown-menu .list-group li a { 
        color: #333; 
        background-color: transparent; 
        border-top:none; 
        border-left:none; 
        border-bottom:none; 
        border-color: transparent;
    }

    .dropdown .dropdown-menu > li a:hover, 
    .dropdown .dropdown-menu > li button:hover,
    .dropdown.scrollable .dropdown-menu  .scroller li a:hover ,
    .dropdown .dropdown-menu .list-group li a:hover { 
        color: @orange; 
        border-color: @orange; 
    }

    .dropdown .dropdown-menu > li.divider { 
        background-color: #E5E5E5; 
    }
}

// Icons
.integration-icon {
    margin-left: 5px;
    width: 20px;
    height: 10px;
    pointer-events: initial;
}

.epic-integration-icon {
    height: 13px;
    border: 1px solid #fff;
    border-radius: 1px;
    margin-right: 5px;
    width: auto;
}
